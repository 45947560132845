// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDcJyHkRV3z5QytnWEiWrPgklhaNSGtGSs",
    authDomain: "sharer-58947.firebaseapp.com",
    projectId: "sharer-58947",
    storageBucket: "sharer-58947.appspot.com",
    messagingSenderId: "944145274849",
    appId: "1:944145274849:web:12cb956aac3fe7f98e24a0",
    measurementId: "G-SPBKBGL711"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, db, storage };
