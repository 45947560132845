import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button, Avatar } from 'antd';

const Headers = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        setProfile(userDoc.data());
   
      } else {
        setUser(null);
      
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const changeTab = (value) => {
    // Here you can map the value to the appropriate route
    if (value === 'Dashboard') {
        navigate('/dashboard');
    } else if (value.startsWith('Profile')) {
        navigate('/profile');
    }
};

  

  return (

<header style={{marginBottom: '15px ',

  display: 'flex',
  justifyContent:'space-between',
  alignItems: 'center'  ,

  padding:"10px",
  borderRadius: '10px',
  border: '1px solid rgb(0,0,0,.1)' 
}}>

  {user ? (
    <>
     <div>
 <Button className="remove-on-mobile" type="dashed" onClick={()=> navigate('/dashboard')}>dekxonn.hu</Button>
 <Button type="link"  onClick={()=> navigate('/dashboard')}>Dashboard</Button>
 </div>


     <Button type="text" onClick={()=> navigate('/profile')} iconPosition="end"
     
     icon={<Avatar size={20} style={{margin:0}} src={profile?.profilePicture}></Avatar>}> {profile?.username}</Button>

 </>
  ): <Button type="dashed" onClick={()=> navigate('/')}>dekxonn.hu</Button>  }
   
    </header> 

  
  );
};

export default Headers;
