import React, { useEffect, useState } from "react";
import { auth, db, storage } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { Input, Button, Typography, Space, message } from "antd";
import { Upload, Avatar } from "antd";
import { RiLogoutCircleRFill } from "react-icons/ri";

const { Title } = Typography;

const Profile = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [newUsername, setNewUsername] = useState("");
  const [newProfilePic, setNewProfilePic] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        setProfile(userData);
        setNewUsername(userData.username);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  const handleProfileUpdate = async () => {
    const userDocRef = doc(db, "users", user.uid);
    let profilePictureUrl = profile.profilePicture;
    if (newProfilePic) {
      profilePictureUrl = newProfilePic;
    }
    try {
      await updateDoc(userDocRef, {
        username: newUsername,
        profilePicture: profilePictureUrl,
      });

      const updatedProfile = await getDoc(userDocRef);
      setProfile(updatedProfile.data());
      message.success("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile. Please try again.");
    }
  };

  const handleFileChange = async (file) => {
    if (!(file instanceof Blob)) {
      message.error("Invalid file type. Please upload a valid image file.");
      return;
    }

    const validFileTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    try {
      if (!validFileTypes.includes(file.type)) {
        message.error("Please upload a valid image file (JPEG, PNG, or WebP).");
        return;
      }

      if (file.size > maxFileSize) {
        message.error("Please upload a file smaller than 5 MB.");
        return;
      }

      // Compress image before uploading
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
      });

      const storageRef = ref(storage, `profile_pictures/${user.uid}/${compressedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle upload progress if needed
        },
        (error) => {
          console.error("Upload failed:", error);
          message.error("Failed to upload image. Please try again.");
        },
        async () => {
          // Upload completed successfully, now get the download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setNewProfilePic(downloadURL);
          message.success("Profile picture uploaded successfully.");
        }
      );
    } catch (error) {
      console.error("Error during file handling:", error);
      message.error("An error occurred. Please try again.");
    }
  };

  const beforeUpload = (file) => {
    handleFileChange(file);
    return false; // Prevent automatic upload by Ant Design
  };

  if (!user || !profile) return <div>Loading...</div>;

  return (
    <div>
      <Title level={4}>Edit Profile</Title>
      <Space>
        <Upload 
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <Button style={{ height: '80px', borderRadius: 100 }} type="dashed">
            <Avatar size={50} src={<img src={profile.profilePicture} alt="avatar" />} /> 
          </Button>
        </Upload>
        <div>
          <Input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            placeholder="Username"
          />
          <div style={{ display: 'flex', gap: '5px' }}>
            <Button onClick={handleProfileUpdate} type="primary">Change Username</Button>
            <Button onClick={handleLogout} type="dashed" icon={<RiLogoutCircleRFill />} danger>Logout</Button>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default Profile;
