import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Input, Button,Typography , Space, Divider, message } from "antd";

import { MdOutlinePassword } from "react-icons/md";
import { SiGmail } from "react-icons/si";
import { RiLoginCircleFill } from "react-icons/ri";
const { Title, Text, Link } = Typography;
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {

    if(!email || !password) {
      message.error("You left something empty.")
      return
    }


    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      let errorMessage = "An error occurred during login.";

      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "The email address is not valid.";
          break;
        case "auth/user-disabled":
          errorMessage = "This user has been disabled.";
          break;
        case "auth/user-not-found":
          errorMessage = "No account found with this email.";
          break;
        case "auth/wrong-password":
          errorMessage = "Incorrect password.";
          break;
        default:
          errorMessage = "Failed to log in.";
          break;
      }

      message.error({
        content: errorMessage,
        duration: 5, // Duration in seconds the message will stay on screen
 
      });
    }
  };

    // Check if the user is already authenticated
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          navigate("/dashboard");
        }
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate]);

  return (
    <div>
        <Title level={1}>dekxonn.hu</Title>
        <Divider  />
      <Title level={4}>Log In</Title>
      <Space     direction="vertical "
       
        style={{
          display: 'flex',
        }}
      >
      <Input 
      size="large" 
      type="email" 
      placeholder="E-mail" 
      value={email} 
      onChange={(e) => setEmail(e.target.value)} 
      prefix={
      <>
      <SiGmail />
      <Divider   type="vertical"/>
      </>
    
    } 
      />

        <Input 
      size="large" 
      type="password" 
      placeholder="Password" 
      value={password} 
      onChange={(e) => setPassword(e.target.value)} 
      prefix={
      <>
         <MdOutlinePassword />
         <Divider   type="vertical"/>
      </>
   

      } 
      />

<Button type="primary" size="large" icon={<RiLoginCircleFill />} onClick={handleLogin}>Login</Button>
</Space>

       <br/> 
      
      <Text>Don't have an account?</Text> <Link href="/register">Register</Link>
    </div>
  );
};

export default Login;
