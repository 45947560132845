import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { Typography, Image, Divider, Button, message, Tag, Tooltip, Popconfirm,Spin,Avatar  } from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { format } from "date-fns";
import parse, { domToReact } from 'html-react-parser';
import { FiEdit3 } from "react-icons/fi";
import { FcDocument } from "react-icons/fc";
import { onAuthStateChanged } from "firebase/auth";
import { MdEdit } from "react-icons/md";
import { MdError } from "react-icons/md";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Helmet } from "react-helmet-async";
const { Title, Text } = Typography;

const Page = () => {
 
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [user, setUser] = useState(null); // State to store the user object
  const [status, setStatus] = useState(200)




  const normalizeContent = (htmlContent) => {
    // Remove multiple consecutive <br> tags
    return htmlContent.replace(/(<br\s*\/?>\s*){5,}/g, '<br />');
  };
  
  // When you start editing, normalize the content before setting it in Quill
  useEffect(() => {
    if (pageData) {
      setEditedContent(normalizeContent(pageData.content));
    }
  }, [pageData]);

  const cleanHtml = (htmlContent) => {
    // Replace multiple <br> tags with a single one
    let cleanedHtml = htmlContent.replace(/(<br\s*\/?>\s*){5,}/g, '<br />');
  
    // Optionally, remove <br> tags that appear alone inside <p> tags
    cleanedHtml = cleanedHtml.replace(/<p>\s*(<br\s*\/?>)+\s*<\/p>/g, '');
  
    // Remove leading and trailing <br> tags
    cleanedHtml = cleanedHtml.replace(/^(<br\s*\/?>)+|(<br\s*\/?>)+$/g, '');
  
    // Further cleanup could involve trimming spaces between tags
    cleanedHtml = cleanedHtml.replace(/\s{2,}/g, ' ');
  
    return cleanedHtml;
  };

  useEffect(() => {
    // Listen to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPage = async () => {
      const docRef = doc(db, "pages", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPageData(docSnap.data());
        setEditedContent(docSnap.data().content); // Initialize with current content
      } else {
        setStatus(404)
      }
    };

    fetchPage();
  }, [id]);

  const replaceTags = (htmlContent) => {
    
    return parse(htmlContent, {
      
      replace: (domNode) => {
        if (domNode.name === 'h1') {
          return <Title level={1}>{domToReact(domNode.children)}</Title>;
        }
        if (domNode.name === 'h2') {
          return <Title level={2}>{domToReact(domNode.children)}</Title>;
        }
        if (domNode.name === 'pre') {
          return   <SyntaxHighlighter language="cpp" style={okaidia} >
          {domToReact(domNode.children)}
        </SyntaxHighlighter>
        }
        if (domNode.name === 'p') {
          if (domNode.children.length === 1 && domNode.children[0].name === 'br') {
            return <><br /></>;
          }
          return (
            <Text style={{ display: 'block' }}>
              {domToReact(domNode.children, {
                replace: (innerNode) => {
                  if (innerNode.name === 'strong') {
                    return <Text strong>{domToReact(innerNode.children)}</Text>;
                  }
                  if (innerNode.name === 'em') {
                    return <Text italic>{domToReact(innerNode.children)}</Text>;
                  }
                  if (innerNode.name === 'u') {
                    return <Text underline>{domToReact(innerNode.children)}</Text>;
                  }
                  if (domNode.children.length === 1 && domNode.children[0].name === 'br') {
                    return <></>; // Render nothing
                  }
                  if (innerNode.name === 'img') {
                    return (
                      <Image
                        src={innerNode.attribs.src}
                        alt={innerNode.attribs.alt || 'Image'}
                        width={200}
                       
                      />
                    );
                  }
                },
              })}
            </Text>
          );
        }
      },
    });
  };

  const confirmDelete = async () => {

      try {
        const pageRef = doc(db, "pages", id);
        await deleteDoc(pageRef);
     
        message.success("Removed page")
      } catch (error) {
        message.error("Error deleting page:", error);
      }

  }

  const handleSaveEdit = async () => {

    if (user.uid !== pageData.authorId) {
      message.error("You are not the author.")
      return
    }

    const cleanedContent = cleanHtml(editedContent);

    try {
      const pageRef = doc(db, "pages", id);
      await updateDoc(pageRef, {
        content: cleanedContent,
        updatedAt: new Date(),
      });
      setPageData((prev) => ({
        ...prev,
        content: cleanedContent,
        updatedAt: new Date(),
      }));
      setIsEditing(false);
      message.success("Page updated successfully!");
    } catch (error) {
      message.error("Failed to update page.");
      console.error("Error updating page:", error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedContent(pageData.content); // Reset the content to the original content
  };

  if (!pageData && status === 404)  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column ',
    gap: 5
  }}>

<span style={{
    fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji' ",
   gap: '5px'   ,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
  }}><MdError style={{color: 'red'}} /> {status}
  
  </span>

  <Button type="primary" onClick={()=> navigate('/')}>Return </Button>

  </div>;

if (!pageData && status === 200) return <div className="loader"><Spin size="large" /></div>

  // Safely handle timestamps, checking their type before using `toDate()`
  const formattedCreatedAt = pageData.createdAt?.toDate
    ? format(pageData.createdAt.toDate(), "MMMM dd, yyyy 'at' HH:mm")
    : format(new Date(pageData.createdAt), "MMMM dd, yyyy 'at' HH:mm");

  const formattedUpdatedAt = pageData.updatedAt?.toDate
    ? format(pageData.updatedAt.toDate(), "MMMM dd, yyyy 'at' HH:mm")
    : pageData.updatedAt
    ? format(new Date(pageData.updatedAt), "MMMM dd, yyyy 'at' HH:mm")
    : null;

  return (
    <div>

<Helmet>
        <title>{pageData.title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={pageData.title} />
        <meta property="og:description" content={pageData.content.slice(0, 100)} />
        <meta property="og:author" content={pageData.username} />
        <meta property="og:site_name" content="dekxonn.hu" />
        <meta property="og:locale" content="en_US" />

      </Helmet>
        <Title level={4}>{pageData.title}</Title>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start' 
    
    }}>
  
      <div>
      
      <Text style={{ display: "flex", alignItems: 'center ' }}><span className="remove-on-mobile" >Created by: </span>
        <Avatar style={{marginLeft: '10px', marginRight: '5px '}} size={25} src={pageData.avatar }></Avatar>
         <Tag color={user?.uid === pageData.authorId ? "geekblue" : "purple"  }>{pageData.username}</Tag>
           </Text>
      <Text type="secondary">
        <Tooltip title="Created At"><FcDocument /></Tooltip> {formattedCreatedAt}
      
        {formattedUpdatedAt && (
          <>
            {" "} <Tooltip title={`Edited at ${formattedUpdatedAt}`}><FiEdit3 /> </Tooltip> 
          </>
        )}
      </Text>
        </div>
    <div style={{
      display: 'flex',
      gap: 5
    }}>
 


 {user && user.uid === pageData.authorId ? (
  !isEditing ? (
    <Button type="dashed" size="small" onClick={() => setIsEditing(true)} icon={<MdEdit />}>
      Edit
    </Button>
  ) : (
    <>
      <Button type="primary" size="small" onClick={handleSaveEdit}>
        Save
      </Button>
      <Button onClick={handleCancelEdit} size="small">
        Cancel
      </Button>
    </>
  )
) : null}


{user && user.uid === pageData.authorId ? (
  !isEditing? (
    <Popconfirm
  title="Delete Document?"
 
  onConfirm={confirmDelete}
  okText="Yes"
  cancelText="No"
>
<Button size="small" type="primary" danger>Delete Page</Button>
</Popconfirm>
  ) : null) : null} 



    </div>
    </div>


      
      <Divider />

      {isEditing ? (
        <div style={{ marginBottom: '20px', padding: '0px', borderRadius: '5px' }}>
          <ReactQuill 
          value={editedContent} 
          onChange={setEditedContent} 
   
       
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline'],
              [{ 'color': [] }, { 'background': [] }],
              ['link'],
              ['image'],
              ['code-block'],
              ['clean']   
            ],
          
          }}
          
          />
    
        </div>
      ) : (
        <div style={{ marginBottom: '20px', padding: '0px', borderRadius: '5px' }}>
          {replaceTags(pageData.content)}
        
        </div>
      )}

      
    </div>
  );
};

export default Page;
