import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { Input, Button,Typography , Space, Divider, message } from "antd";
import { FaUser } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { SiGmail } from "react-icons/si";
import { RiLoginCircleFill } from "react-icons/ri";
const { Title, Text, Link } = Typography;

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(""); // Add a username state
  const navigate = useNavigate();

  const handleRegister = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !password || !username) {
      message.error("You left something empty.")
      return
    }

    if (username.length < 4) {message.error("Username minimum length 4"); return}
    if (email.length < 4) {message.error("Enter a valid E-Mail"); return }
    if (!emailRegex.test(email)) {
      message.error("Invalid email address");
      return;
    }
    if (password.length < 6) {message.error("Password minimum length 6"); return }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Set default profile picture URL (you can change this to any default image URL)
      const defaultPfpURL = "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg";

      // Save additional user info in Firestore
      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: user.email,
        profilePicture: defaultPfpURL,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

    // Check if the user is already authenticated
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          navigate("/dashboard");
        }
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate]);

  return (
    <div>
         <Title level={1}>dekxonn.hu</Title>
        <Divider  />
      <Title level={4}>Register</Title>
      <Space     direction="vertical "
       
        style={{
          display: 'flex',
        }}
      >
      <Input
        type="text"
        size="large"
        prefix={
            <>
            <FaUser />
            <Divider type="vertical"/>
            </>
        }
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
    <Input 
      size="large" 
      type="email" 
      placeholder="E-mail" 
      value={email} 
      onChange={(e) => setEmail(e.target.value)} 
      prefix={
      <>
      <SiGmail />
      <Divider   type="vertical"/>
      </>
    
    } 
      />
           <Input 
      size="large" 
      type="password" 
      placeholder="Password" 
      value={password} 
      onChange={(e) => setPassword(e.target.value)} 
      prefix={
      <>
         <MdOutlinePassword />
         <Divider   type="vertical"/>
      </>
   

      } 
      />

        <Button type="primary" size="large" icon={<RiLoginCircleFill />} onClick={handleRegister}>Register</Button>
      </Space>

      <br/> 
      
      <Text>Already have an account?</Text> <Link href="/login">Login</Link>
    </div>
  );
};

export default Register;
