import React, { useState, useEffect } from "react";
import { auth, db} from "../firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa6";
import { DownOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import { 
  Input,
  Button,
  Typography,
  Space,
  Divider,
  List,
  message,
  Tooltip,
  Tag,
  Popconfirm,
  Dropdown,
  Spin,
  Menu

} from "antd";
import { MdEdit } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { format } from "date-fns";
import { FcInfo } from "react-icons/fc";


import RichTextEditor from 'quill-react-commercial';
import 'quill-react-commercial/lib/index.css';

const { Title, Text, Link} = Typography;

const Dashboard = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");


  const [pages, setPages] = useState([]);
  const [editingPageId, setEditingPageId] = useState(null); // Track the ID of the page being edited
  const [loading, setLoading] = useState(true); // Loading state
  const [recentlyViewed, setRecentlyViewed] = useState([]); // Recently viewed pages
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // State to store the user object
const [username, setUsername] = useState("")
  const [avatar, setAvatar] = useState(null)

useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      setUser(user); // Set the user if logged in

      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUsername(userData.username); // Set the username from Firestore
          setAvatar(userData.profilePicture)
        } else {
          console.error("No user document found!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        message.error("Failed to fetch user data.");
      }

      fetchPages(user.uid); // Fetch pages once the user is available
      fetchRecentlyViewedPages(); // Fetch recently viewed pages
    } else {
      message.error("No user is logged in.");
      navigate("/login"); // Redirect to login if no user is logged in
    }
    setLoading(false); // Set loading to false after auth state is resolved
  });

  return () => unsubscribe(); // Clean up the listener on unmount
}, [navigate]);



  const fetchPages = async (userId) => {
    try {
      const q = query(collection(db, "pages"), where("authorId", "==", userId));
      const querySnapshot = await getDocs(q);
      const userPages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPages(userPages);
    } catch (error) {
      message.error("Error fetching pages:", error);
    }
  };

  const fetchRecentlyViewedPages = async () => {
    const recentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedPages")) || [];
    try {
      const pages = await Promise.all(
        recentlyViewed.slice(0, 10).map(async (pageId) => {
          const pageDoc = await getDoc(doc(db, "pages", pageId));
          return { id: pageId, ...pageDoc.data() };
        })
      );
      setRecentlyViewed(pages);
    } catch (error) {
      message.error("Error fetching recently viewed pages:", error);
    }
  };

  const handleDeleteRecentlyViewed = (pageId) => {
    const updatedRecentlyViewed = recentlyViewed.filter((page) => page.id !== pageId);
    setRecentlyViewed(updatedRecentlyViewed);
    const updatedPageIds = updatedRecentlyViewed.map((page) => page.id);
    localStorage.setItem("recentlyViewedPages", JSON.stringify(updatedPageIds));
  };

 

  const handleCreateOrUpdatePage = async () => {
    if (!title || !content) {
      message.error("Title and content are required.");
      return;
    }

 

    try {
      if (editingPageId) {
        // If editing, update the page
        const pageRef = doc(db, "pages", editingPageId);
        await updateDoc(pageRef, {
          title,
          content,
          avatar: avatar,
          username: username,
          updatedAt: new Date(),
        });
        message.success("Successfully edited page!")
      } else {
        // If not editing, create a new page
        await addDoc(collection(db, "pages"), {
          title,
          content,
          avatar: avatar,
          username: username, 
          authorId: user.uid,
          createdAt: new Date(),
        });
        message.success("Successfully created page!")
      }
      // Clear the form and editing state
      clearForm();
      fetchPages(user.uid); // Refresh pages after creation or update
    } catch (error) {
      message.error("Error creating/updating page:", error); 
    }
  };

  const handleEditClick = (page) => {
    setTitle(page.title);
    setContent(page.content);


    setEditingPageId(page.id); // Set the editing page ID
  };

  const handleDeletePage = async (pageId) => {
    try {
      const pageRef = doc(db, "pages", pageId);
      await deleteDoc(pageRef);
      // Remove the deleted page from state
      setPages(pages.filter((page) => page.id !== pageId));
      fetchPages(user.uid); // Refresh pages after deletion
      message.success("Removed page")
    } catch (error) {
      message.error("Error deleting page:", error);
    }
  };

  const clearForm = () => {
    setTitle("");
    setContent("");
  
    setEditingPageId(null); // Reset the editing page ID
  };



 

  const handlePageView = (pageId) => {
    // Save to recently viewed pages
    let recentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedPages")) || [];
    recentlyViewed = [pageId, ...recentlyViewed.filter((id) => id !== pageId)];
    localStorage.setItem("recentlyViewedPages", JSON.stringify(recentlyViewed.slice(0, 10)));
    fetchRecentlyViewedPages();
    navigate(`/page/${pageId}`);
  };


  const getMenu = (item) => (
    <Menu>
          
      <Menu.Item key="edit" onClick={() => handleEditClick(item)}>
        Edit
      </Menu.Item>
      <Popconfirm
        title="Delete Document?"
        
        onConfirm={() => handleDeletePage(item.id)}
        okText="Yes"
        cancelText="No"
      >
      <Menu.Item key="delete" >
        Delete
      </Menu.Item>
      </Popconfirm>
    </Menu>
  );


  if (loading) {
    return <div className="loader"><Spin size="large" /></div>
  }

  return (
    <div>
      <Title level={4}> {editingPageId ? "Update Page" : "Create Page"} </Title>
      
      <Space style={{gap:5, display: 'flex'}} direction="vertical">

      <Text>Title of your page</Text>
      <Input
      type="text"
      placeholder="Title"
      size="large"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      />
      
      
      <Text>Main content of your page
      <Tooltip title="You can format your text, add images, lists, change colors, background colors, add links etc.">
      <Button type="text" icon={<FcInfo />} size="small" />
      </Tooltip>
        </Text> 

       
      <ReactQuill
          value={content}
          onChange={setContent}
          placeholder="..."
          theme={"snow"}
         
          modules={{
          
            toolbar: [
              [{ header: '1' }, { header: '2' }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline', 'blockquote'],
              [{ 'color': [] }, { 'background': [] }],
              ['link'],
              ['image'],
              ['code-block'],
              ['clean']   
            ],
          }}
        />
 

   
      <div style={{
        display: 'flex',
        justifyContent: 'center', alignItems: 'center', gap: "5px", marginTop: '20px'
      }}>
      <Button type="primary" style={{width: '100% ', height: '50px'}} onClick={handleCreateOrUpdatePage}>
        {editingPageId ? "Update Page" : "Create Page"} 
      </Button>
      {editingPageId && <Button type="dashed" style={{height: '50px'}} onClick={clearForm}>Cancel Edit</Button>}
      </div>

      </Space>

      <Divider />

  
      <Title level={4}>Your Pages</Title>
{pages.length > 0 ? (



<List
    itemLayout="horizontal"
    dataSource={pages}
    renderItem={(item, index) => (
      <List.Item
      actions={
        [
        
           <Dropdown
           key={item.id}
           overlay={getMenu(item)}
           trigger={['click']}
         >
           <a onClick={(e) => e.preventDefault()}>
             <Space>
               {item.label}
               <HiOutlineDotsVertical />
             </Space>
           </a>
         </Dropdown>
            ]
    }
      >
        <List.Item.Meta
       
          title={<Link onClick={() => handlePageView(item.id)}><Tag>{item.username}</Tag>{item.title}</Link>}
      
          
        />
      </List.Item>
    )}
  />
  
) : (
  <Text keyboard>No pages yet..</Text>
)}
 
<br/><br/>
<Title level={4} >Recently Viewed Pages</Title>
{recentlyViewed.length > 0 ? (

<List
    itemLayout="horizontal"
    dataSource={recentlyViewed}
    renderItem={(item, index) => (
      <List.Item
      actions={
        [
        <Button onClick={() => handlePageView(item.id)} icon={<FaRegEye />}></Button>,
        <Button onClick={() => handleDeleteRecentlyViewed(item.id)}><RiDeleteBinLine /> From list</Button>
            ]
    }
      >
        <List.Item.Meta
       
          title={<Link onClick={() => handlePageView(item.id)}>{item.title}</Link>}
       
          
        />
      </List.Item>
    )}
  />
 
) : (
  <Text keyboard>No recently viewed pages..</Text>
)}
    </div>
  );
};

export default Dashboard;
