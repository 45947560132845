import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import Register from "./component/Register";
import Login from "./component/Login";
import Dashboard from "./component/Dashboard";
import Profile from "./component/Profile";
import PageView from "./component/Page";
import Headers from "./component/Header";
import { HelmetProvider } from "react-helmet-async";
function App() {
  const [user] = useAuthState(auth);

  return (
    <HelmetProvider>
    <Router>
     <Headers />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/page/:id" element={<PageView />} />
        {user ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </>

        ) : (
          <>
            <Route path="/" element={<Navigate to="/login" replace />} />
          </>
        )}
      </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;
